<template>
  <div name="index">
    <div class="container" style="margin: 1rem 10px 0">
      <div class="news">
        <div class="news-bg">{{ $t('index.complaintQuery') }}</div>
        <div class="list">
          <div class="mobile_yzm">
            <input type="number"
              :placeholder="$t('index.entryPhone')"
              class="mobile"
              v-model="mobile"
            />
            <button @click="getYzm"
              class="yzm_btn"
              v-bind:class="{ disabled: count_over }"
              :disabled="count_over"
            >
              {{ yzm_text }}
            </button>
          </div>
          <input type="number" class="sfz" v-model="yzm"
            :placeholder="$t('index.complaint.entryCode')"
          />
          <button
            class="button col button-fill button-large search"
            @click="checkYzm"
          >
            {{ search_text }}
          </button>
        </div>
        <div class="list tousu_list" v-show="canSearch">
          <ul v-if="tousuNews.length > 0">
            <li v-for="item in tousuNews" :key="item.id">
              <div class="item-content">
                <div class="item-inner">
                  <div class="item-title">
                    <router-link
                      :to="{
                        name: 'tousu_search_detail',
                        params: { id: item.id },
                      }"
                    >
                      <span :class="item.state === '完结' ? 'yes' : 'no'">
                        [{{ item.state }}]
                      </span>
                      {{ item.title }}
                    </router-link>
                  </div>
                  <div class="item-after">
                    {{ item.createTime ? item.createTime.slice(5) : "" }}
                  </div>
                </div>
              </div>
            </li>
          </ul>
          <div v-else style="padding: 1rem 0; text-align: center">
            <h3>{{ $t('public.noData') }}</h3>
          </div>
        </div>
      </div>
      <div class="footer">
        <p>{{ $t('footer.producer') }}：{{ $t('footer.producerName') }}</p>
        <!-- <p>{{ $t('footer.contactAddress') }}：{{ $t('footer.contactAddressName') }}</p> -->
      </div>
    </div>
  </div>
</template>
<script>
import BASE from "../utils/base";

export default {
  name: "app",
  components: {},
  computed: {},
  watch: {},
  data: () => ({
    result: [],
    active: 1,
    todayView: null,
    totalView: null,
    zhengceNewsList: [],
    id: null,
    zcname: "",
    zctype: "全部政策",
    searchZc: "",
    searchZcParams: "",
    pageNo: 1,
    pageSize: 10,
    mobile: "",
    yzm_text: $t('index.complaint.getCode'),
    yzm: "",
    tousuNews: [],
    canSearch: false,
    search_text: $t('public.queryBtn'),
    timer: null,
    count_over: false,
  }),
  //   created() {
  //       this.getList();
  //   },
  methods: {
    getYzm() {
      let that = this;
      this.axios
        .post(BASE.genUrl("phoneCode/send_msg", { phone: this.mobile }))
        .then(() => {
          that.issend = true;
          this.count_over = 60;
          this.timer = setInterval(this.countdown, 1000);
          alert("验证码已发送！");
        });
    },
    countdown() {
      console.log(this.count_over);
      if (this.count_over > 1) {
        this.count_over -= 1;
        this.yzm_text = "重新获取(" + this.count_over + "s)";
      } else {
        this.count_over = false;
        this.yzm_text = this.$t('index.complaint.getCode');
        clearInterval(this.timer);
      }
    },
    checkYzm() {
      let that = this;
      this.search_text = this.$t('index.complaint.querying');
      this.axios
        .post(
          BASE.genUrl("phoneCode/verify_msg", {
            phone: this.mobile,
            code: this.yzm,
          })
        )
        .then((res) => {
          that.search_text = this.$t('public.queryBtn');
          console.log(res);
          if (!res.data.success) {
            alert(res.data.message);
            return false;
          }
          that.count_over = false;
          this.getList();
        });
    },
    getList() {
      // 投诉
      let that = this;
      this.axios
        .get(BASE.genUrl("zxSuperviseLetter/list", { phone: this.mobile }))
        .then((res) => {
          let data = res.data;
          if (data.result) {
            that.canSearch = true;
            that.tousuNews = data.result.records;
          }
        });
    },
  },
};
</script>
<style scoped>
a.disabled {
  color: #ccc;
}
.data-table-pagination {
  font-size: 14px;
  padding: 1rem;
}
.data-table-pagination a {
  padding: 0 1rem;
}
.detail h3 {
  color: #fff;
  font-size: 16px;
}
.detail h4 {
  color: #fff;
  font-size: 14px;
}
.source {
  text-align: left;
  color: #fff;
  padding: 0.1rem;
  font-size: 14px;
  margin-bottom: 0.3rem;
}
.source a {
  color: #fff;
}
.detail img {
  width: 100%;
}
.zclist li {
  text-align: left;
}
.zclist li a {
  color: #fff;
  font-size: 14px;
}
.item-content {
  padding: 0.5rem 0;
}
.item-inner {
  display: flex;
  flex-direction: row;
}
.item-title {
  padding-right: 0.1rem;
  font-size: 14px;
  width: 85%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.item-title span.yes {
  font-weight: bold;
  color: #b51414;
}
.item-title span.no {
  font-weight: bold;
  color: #fe9b4a;
}
.item-after {
  width: 15%;
}
.tab {
  display: none;
}
.tab-active {
  display: block;
}
#tab3 .tab3-top,
#tab2 .tab2-top {
  display: flex;
  flex-direction: row;
}
#tab3 .tab3-middle,
#tab2 .tab2-middle {
  display: flex;
  flex-direction: row;
}
#tab3 .item,
#tab2 .item {
  width: 33%;
  display: flex;
  flex-direction: row;
  justify-content: center; /* 水平居中*/
  align-items: center; /* 垂直居中*/
  margin-right: 0.1rem;
}
#tab2 .item .l {
  width: 50%;
  display: block;
}
#tab2 .item .r {
  border-radius: 0.5rem;
  font-size: 12px;
  background: #fff;
  display: block;
}
#tab2 .item .r h3 {
  margin: 0;
  padding: 0;
}
#tab2 .item .r div {
  padding: 0 0.2rem;
}
#tab2 .info-item .t {
  font-weight: bold;
  font-size: 14px;
}
#tab2 .info-div,
#tab3 .info-div {
  width: 50%;
  margin-right: 0.1rem;
  margin-top: 20px;
}
#tab2 .info-title,
#tab2 .info-item,
#tab3 .info-title,
#tab3 .info-item {
  text-align: center;
}
#tab2 .info-items,
#tab3 .info-items {
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  padding: 0.3rem 0;
  font-size: 12px;
  border: 1px solid #999;
  border-top: 0;
  color: #999;
}
#tab2 .info-title,
#tab3 .info-title {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  padding: 0.3rem 0;
  font-size: 12px;
  color: #fff;
  background: #b51414;
}
#tab2 .item,
#tab3 .item {
  border-radius: 0.5rem;
  color: #fe9b4a;
  background: #b51414;
  text-align: center;
  padding: 0.5rem 0;
}
.footer {
  color: #fe9b4a;
  text-align: center;
  font-size: 12px;
  margin-top: 1rem;
}
.list {
  margin-top: 1rem;
  padding: 1rem 0.8rem;
  font-size: 14px;
}
.tousu_list {
  margin-top: 0rem;
}
.list ul:before {
  background: none;
}
.list .zhengce-select {
  display: flex;
  flex-direction: row;
}
.list .zhengce-select-div {
  width: 100%;
}
.list .zhengce-select-div select {
  text-align: center;
  width: 100%;
  font-size: 16px;
  padding: 0.5rem 0;
  margin-top: 1rem;
  border-radius: 0.5rem;
  border: 1px solid #b51414;
}
.news-bg {
  background: url("../assets/tab-bg.png") center top no-repeat;
  background-size: 60%;
  position: absolute;
  width: 100%;
  top: -5px;
  font-weight: bold;
  color: #fff;
  font-size: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5em 0;
}
.news {
  background: #fff;
  padding-top: 0.5rem;
  position: relative;
  border-radius: 0.5rem;
}
ol,
ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.payinfo {
  margin-top: 0.5rem;
  display: flex;
  border-radius: 0.5rem;
  background-image: linear-gradient(0deg, #c82214 51%, #ed872e 95%);
}
.payinfo .payinfo-item {
  flex-grow: 1;
  display: flex;
  /* padding: .5rem; */
}
.payinfo .payinfo-item .payinfo-item-l {
  /* flex-grow: 1; */
  display: flex;
  width: 30%;
  justify-content: center; /* 水平居中*/
  align-items: center; /* 垂直居中*/
}
.payinfo .payinfo-item .payinfo-item-l img {
  width: 50%;
}
.payinfo .payinfo-item .payinfo-item-r {
  color: #fff;
  padding: 1rem 0;
  text-align: left;
  font-size: 14px;
  /* width: 66%;
  float: left; */
}
.payinfo .payinfo-item dd,
.payinfo .payinfo-item dt {
  text-align: left;
}
.container .nav-tabs {
  display: flex;
}
.container .nav-tabs a.tab-link-active {
  color: #b51414;
  background: #fff;
  /* background: url('../../static/choose-bg.png') 100% 100% no-repeat; */
}
.container .nav-tabs .tab1-link,
.container .nav-tabs .tab2-link {
  margin-right: 2px;
}
.container .nav-tabs a {
  /* width: 33%; */
  font-size: 14px;
  color: #fff;
  flex-grow: 1;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  height: 35px;
  line-height: 35px;
  font-weight: bold;
  /* background: #b51414; */
  background-image: linear-gradient(0deg, #c82214 51%, #ed872e 95%);
}
.container .news .list {
  text-align: left;
  padding: 0.8rem;
}
.container .tabs #tab1 input.sfz,
.container .news input.sfz {
  border: 1px solid #b51414;
  font-size: 1rem;
  padding: 0.6rem 0;
  border-radius: 0.5rem;
  width: 100%;
  margin: 0.5rem 0;
  text-align: center;
}
.container .news input.mobile {
  border: 1px solid #b51414;
  font-size: 1rem;
  padding: 0.6rem 0;
  border-radius: 0.5rem;
  width: 70%;
  margin: 0.5rem 0;
  text-align: center;
}

.container .news button.search,
.container #tab1 button {
  background: #b51414;
  border: 0;
  color: #fff;
  font-size: 1rem;
  padding: 0.6rem 0;
  border-radius: 0.5rem;
  width: 100%;
  margin: 0.5rem 0;
  text-align: center;
}

.container .news button.yzm_btn {
  background: #b51414;
  border: 0;
  color: #fff;
  font-size: 12px;
  padding: 0.6rem 0.3rem;
  margin-left: 0.5rem;
  border-radius: 0.5rem;
  /* width: 25%; */
  /* margin: .5rem 0; */
  text-align: center;
}
.container .news button.disabled {
  background: #ccc;
  color: #b51414;
}

.container #tab1 .title {
  display: block;
  text-align: center;
  font-size: 1rem;
  font-weight: bold;
}
.container .tabs .tab-active {
  background: #fff;
  padding: 0.5rem;
  border-radius: 0 0 0.5rem 0.5rem;
}
.banner {
  width: 100%;
  height: auto;
}
.page {
  background: #b51414;
}
.search {
  margin-top: 10px;
  padding: 10px;
  background: #f7f7f7;
  border-radius: 0.5rem 0.5rem;
  text-align: center;
}
.search span {
  font-size: 14px;
  display: block;
  text-align: center;
  color: #999;
  margin-bottom: 20px;
}
</style>
